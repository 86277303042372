/**
 * This file is loaded and the `manifest()` is executed when the host application starts.
 * static import should be kept to a minimum.
 * Application specific code should be loaded in the component/activate function.
 */
import type { NavItem } from "@sparky/framework";
import icon from "./file-code-solid.svg";
import { loadLocaleData } from "./i18n/loadLocaleData";
import { isFeatureEnabled } from "./index.ctx";

export function manifest(): Record<string, unknown> {
  const locale = navigator.language ? navigator.language : "en";
  const intl = i18n(locale);

  const epmRouteConfig =
    window.location.href.indexOf("dev") > -1
      ? ({
          key: "epm",
          title: intl.formatMessage({ defaultMessage: "Endpoint Manager" }),
          contentClassName: "panwds-tw3",
          expandable: true,
          path: "/epm",
          mergeNav: true,
          noRBACheck: true,
          exact: false,
          avail(_param, _licenseInfo, featuresInfo) {
            return isFeatureEnabled(featuresInfo, "ztna_agent");
          },
          icon,
          children: [
            {
              key: "iventory",
              title: intl.formatMessage({ defaultMessage: "Inventory" }),
              // Can have its own className
              contentClassName: "panwds-tw3",
              path: "/epm/inventory",
              noRBACheck: true,
            },
          ],
          component: () => bootstrap({ locale }),
        } as NavItem)
      : {
          key: "manage",
          title: intl.formatMessage({ defaultMessage: "Manage" }),
          path: "/manage",
          noRBACheck: true,
          className: "parent-node",
          contentClassName: "manage-root",
          mergeNav: true,
          exact: false,
          expandable: true,
          children: [
            {
              title: intl.formatMessage({ defaultMessage: "Endpoint Manager" }),
              key: "manage-endpoint",
              contentClassName: "panwds-tw3",
              path: "/manage/epm",
              isSetup: true,
              noRBACheck: true,
              avail(_param, _licenseInfo, featuresInfo) {
                return isFeatureEnabled(featuresInfo, "ztna_agent");
              },

              children: [
                {
                  key: "inventory",
                  title: intl.formatMessage({ defaultMessage: "Inventory" }),
                  isSetup: true,
                  path: "/manage/epm/inventory",
                  avail(_param, _licenseInfo, featuresInfo) {
                    return isFeatureEnabled(featuresInfo, "ztna_agent");
                  },
                  noRBACheck: true,
                  component: () => bootstrap({ locale }),
                },
              ],
            } as NavItem,
          ],
        };
  return epmRouteConfig;
}

const messages: Record<string, Record<string, string>> = {
  ja: {},
  fr: {},
  sp: {},
  zh_CN: {},
  zh_TW: {},
};

function i18n(language: string) {
  return language === "en"
    ? {
        formatMessage({ defaultMessage }: { defaultMessage: string }) {
          return messages[language]?.[defaultMessage] ?? `🙈 ${defaultMessage}`;
        },
      }
    : {
        formatMessage({ defaultMessage }: { defaultMessage: string }) {
          return defaultMessage;
        },
      };
}

async function bootstrap({ locale }: { locale: string }) {
  const messages = await loadLocaleData(locale);
  const module = await import("./module");
  return module.activate({ locale, messages });
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const epm = ({ sparkyFramework: { onAuthed, StartupManager } }) => {
  onAuthed(
    ({
      getFeatures,
      getLicenseInfo,
      getFqdnInfo,
      getAuthInfo,
      getSubTenantInfo,
    }: any) => {
      getFeatures();
      getLicenseInfo();
      getFqdnInfo();
      getAuthInfo();
      if (
        StartupManager.getConfigManagedType() === StartupManager.PANORAMA_CONFIG
      ) {
        getSubTenantInfo();
      }
    }
  );

  return Promise.resolve(manifest());
};
export default epm;
