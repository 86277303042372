import { MessageFormatElement } from "react-intl";
import { Locale } from "./types";

export function loadLocaleData(
  locale: Locale | string
): Promise<Record<string, MessageFormatElement[]>> {
  switch (locale) {
    case "es":
      return import("./compiled-lang/es.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
    case "fr":
      return import("./compiled-lang/fr.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
    case "ja":
      return import("./compiled-lang/ja.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
    case "zh_CN":
      return import("./compiled-lang/zh_CN.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
    case "zh_TW":
      return import("./compiled-lang/zh_TW.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
    default:
      return import("./compiled-lang/en.json") as unknown as Promise<
        Record<string, MessageFormatElement[]>
      >;
  }
}
