import { FeatureInfo } from "@sparky/framework";

export const isFeatureEnabled = (
  featureInfo: FeatureInfo[] | undefined,
  featureKey: string
) => {
  if (!featureInfo) return false;

  // return featureInfo.some(...)
  for (let i = 0; i < featureInfo.length; i++) {
    if (featureInfo[i].name === featureKey && featureInfo[i].toggle === 1) {
      return true;
    }
  }
  return false;
};
